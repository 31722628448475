import { routeNames } from 'constants';
import Layout from 'layout';
import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

const Home = lazy(() => import('pages/home'));
const Food = lazy(() => import('pages/food'));
const Tickets = lazy(() => import('pages/tickets'));
const Partners = lazy(() => import('pages/partners'));
const Contact = lazy(() => import('pages/contact'));
const Volunteer = lazy(() => import('pages/volunteer'));
const About = lazy(() => import('pages/about'));
const Program = lazy(() => import('pages/program'));
const IndividualEvent = lazy(() => import('pages/individual-event'));
const Ocreate = lazy(() => import('pages/o-create'));

const AppRoutes = () => {
  const routes = useRoutes([
    {
      path: '',
      element: <Layout />,
      children: [
        {
          path: routeNames.home,
          element: <Home />,
        },
        {
          path: routeNames.tickets,
          element: <Tickets />,
        },
        {
          path: routeNames.food,
          element: <Food />,
        },
        {
          path: routeNames.partners,
          element: <Partners />,
        },
        {
          path: routeNames.contact,
          element: <Contact />,
        },
        {
          path: routeNames.volunteer,
          element: <Volunteer />,
        },
        {
          path: routeNames.about,
          element: <About />,
        },
        {
          path: routeNames.program,
          element: <Program />,
        },
        {
          path: routeNames.individualevent,
          element: <IndividualEvent />,
        },
        {
          path: routeNames.ocreate,
          element: <Ocreate />,
        },
      ],
    },
  ]);
  return <Suspense fallback={<>...Loading</>}>{routes}</Suspense>;
};

export default AppRoutes;

import React from 'react';
import { footerMapping } from 'constants';
import styles from './styles.module.css';
import { footerLogoMapping } from 'constants';
import { Image } from 'components/common/image';

const Footer = () => {
  const filteredLogos = footerLogoMapping.slice(1, 3).flat();

  const renderLeftSAection = () => {
    return (
      <section className={styles.leftSectionStyle}>
        <div className={styles.mobilePrivacyAndContactStyle}>
          <div className={styles.mobilePrivacyPolicyStyle}>
            <div className={styles.privacyPolicyStyle}>
              <p className={styles.priticalInformationTitleStyle}>
                PRACTICAL INFORMATION
              </p>
              <p className={styles.privacyPolicyTitleStyle}>PRIVACY POLICY</p>
            </div>

            <div className={styles.mobileSocialMediaStyle}>
              <p className={styles.socialMediaTitleStyle}>INSTAGRAM</p>
              <p className={styles.socialMediaTitleStyle}>FACEBOOK</p>
            </div>
          </div>
          <div className={styles.mobileInfoSectionStyle}>
            <div className={styles.mobileInfoStyle}>
              <>
                {footerMapping.map((i) => {
                  return (
                    <div className={styles.locationStyle}>
                      <p className={styles.infoTitleStyle}>{i.title}</p>
                      <p className={styles.infoTitleStyle}>{i.location}</p>
                      <p className={styles.infoTitleStyle}>{i.address}</p>
                    </div>
                  );
                })}
              </>

              <div className={styles.contactStyle}>
                <p className={styles.contactTitleStyle}>CONTACT</p>
                <p className={styles.contactInfoTitleStyle}>INFO@ODAYS.DK</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.socialAndInfoStyle}>
          <div className={styles.socialMediaStyle}>
            <p className={styles.socialMediaTitleStyle}>INSTAGRAM</p>
            <p className={styles.socialMediaTitleStyle}>FACEBOOK</p>
          </div>
          <div className={styles.infoStyle}>
            {footerMapping.map((i) => {
              return (
                <div className={styles.locationStyle}>
                  <p className={styles.infoTitleStyle}>{i.title}</p>
                  <p className={styles.infoTitleStyle}>{i.location}</p>
                  <p className={styles.infoTitleStyle}>{i.address}</p>
                </div>
              );
            })}

            <div className={styles.contactStyle}>
              <p className={styles.contactTitleStyle}>CONTACT</p>
              <p className={styles.contactInfoTitleStyle}>INFO@ODAYS.DK</p>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const renderRightSection = () => {
    return (
      <section className={styles.rightSectionStyle}>
        <div className={styles.rightTopImgStyle}>
          {footerLogoMapping[0]?.map((item) => {
            return (
              <Image
                image={item?.image}
                onClick={() => window.open(item.url)}
                customImageContainerStyle={styles.footerTopUrlImgStyle}
                customImageStyle={styles.customImgStyle}
              />
            );
          })}
        </div>
        <div className={styles.rightBottomImgStyle}>
          <div className={styles.bottomImgIndexingStyle}>
            {footerLogoMapping[1]?.map((item) => {
              return (
                <Image
                  image={item?.image}
                  onClick={() => window.open(item.url)}
                  customImageContainerStyle={styles.footerBottomUrlImgStyle}
                  customImageStyle={styles.customImgStyle}
                />
              );
            })}
          </div>

          <div className={styles.bottomImgIndexingStyle}>
            {footerLogoMapping[2]?.map((item) => {
              return (
                <Image
                  image={item?.image}
                  onClick={() => window.open(item.url)}
                  customImageContainerStyle={styles.footerBottomUrlImgStyle}
                  customImageStyle={styles.customImgStyle}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.mobileBorderStyle}></div>
        <div className={styles.rightBottomMobileImgStyle}>
          {filteredLogos?.map((item) => {
            return (
              <Image
                image={item?.image}
                onClick={() => window.open(item.url)}
                customImageContainerStyle={styles.footerBottomUrlImgStyle}
                customImageStyle={styles.customImgStyle}
              />
            );
          })}
        </div>
      </section>
    );
  };
  return (
    <main className={styles.footerContainerStyle}>
      {renderLeftSAection()}
      {renderRightSection()}
    </main>
  );
};

export default Footer;

import React, { useState } from 'react';
import { Image } from 'components/common/image';
import { crossLogo, hamburgerLogo, mainLogo } from 'resources/images';
import { Button } from 'components/common/button';
import styles from './styles.module.css';
import { routeNames } from 'constants';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  //navigation:-
  const navigate = useNavigate();

  //state:-
  const [showMenu, setShowMenu] = useState(false);

  //mapping data:-
  const navigateData = [
    {
      title: 'FOOD',
      link: routeNames.food,
    },
    {
      title: 'PARTNERS',
      link: routeNames.partners,
    },
    {
      title: 'TICKETS',
      link: routeNames.tickets,
    },
    {
      title: 'ABOUT',
      link: routeNames.about,
    },
    {
      title: 'PROGRAM',
      link: routeNames.program,
    },
    {
      title: 'O-CREATE',
      link: routeNames.ocreate,
    },
    {
      title: 'VOLUNTEER',
      link: routeNames.volunteer,
    },
    {
      title: 'CONTACT',
      link: routeNames.contact,
    },
  ];

  const renderLeftSection = () => {
    return (
      <section className={styles.leftSectionStyle}>
        <Image
          image={mainLogo}
          altText="main-logo"
          onClick={() => navigate(routeNames.home)}
          customImageContainerStyle={styles.mainLogoImgStyle}
        />
        <div className={styles.registerMonthStyle}>
          <p className={styles.registerMonthTitleStyle}>AUGUST 1 - 3 / 2024</p>
          <p className={styles.registerAddressTitleStyle}>
            REFSHALEØEN, 1432 COPENHAGEN (DK)
          </p>
        </div>
      </section>
    );
  };

  const renderRightSection = () => {
    return (
      <>
        <section className={styles.rightSectionStyle}>
          {navigateData.map((i) => {
            return (
              <Button
                title={i.title}
                customBtnContainerStyle={styles.buttonContainerStyle}
                customBtnTitleStyle={styles.buttonTitleStyle}
                onClick={() => navigate(i.link)}
              />
            );
          })}
        </section>
        <section className={styles.rightTabSectionStyle}>
          <Image
            image={hamburgerLogo}
            altText="menu-logo"
            onClick={() => setShowMenu(true)}
          />
        </section>
      </>
    );
  };

  const renderMenuSection = () => {
    return (
      <section className={styles.rightMenuSectionStyle}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '32px',
            }}
          >
            <Image
              image={mainLogo}
              altText="main-Logo"
              customImageContainerStyle={styles.customMainIconStyle}
              onClick={() => {
                navigate(routeNames.home);
                setShowMenu(false);
              }}
            />
            <Image
              image={crossLogo}
              altText="crossLogo"
              customImageContainerStyle={styles.customCrossIconStyle}
              onClick={() => setShowMenu(false)}
            />
          </div>
          <div className={styles.menuItemsStyle}>
            {navigateData.map((i) => {
              return (
                <>
                  <h3
                    className={styles.menuTitleStyle}
                    onClick={() => {
                      navigate(i.link);
                      setShowMenu(false);
                    }}
                  >
                    {i.title}
                  </h3>
                </>
              );
            })}
          </div>
        </div>
      </section>
    );
  };

  return (
    <main className={styles.headerContainerStyle}>
      {renderLeftSection()}
      {renderRightSection()}
      {showMenu && <>{renderMenuSection()}</>}
    </main>
  );
};

export default Header;

import {
  abenLogo,
  aboutBannerImgEight,
  aboutBannerImgFive,
  aboutBannerImgFour,
  aboutBannerImgNine,
  aboutBannerImgOne,
  aboutBannerImgSeven,
  aboutBannerImgSix,
  aboutBannerImgTen,
  aboutBannerImgThree,
  aboutBannerImgTwo,
  aboutGalleryImgEight,
  aboutGalleryImgNine,
  anarkistLogo,
  bombaySapphireLogo,
  clinicquelLogo,
  clugerLogo,
  drjartLogo,
  dummmyImgFour,
  dummyImgOne,
  dummyImgThree,
  dummyImgTwo,
  homeBannerImgEight,
  homeBannerImgFive,
  homeBannerImgFour,
  homeBannerImgOne,
  homeBannerImgSeven,
  homeBannerImgSix,
  homeBannerImgThree,
  homeBannerImgTwo,
  liveNationalLogo,
  netCompanyLogo,
  oCreateIconFive,
  oCreateIconFour,
  oCreateIconOne,
  oCreateIconThree,
  oCreateIconTwo,
  redBullLogo,
  royalOakLogo,
  sticksNSushilLogo,
  volunteerImgThree,
  volunteerImgTwo,
} from 'resources/images';

//routesNames
export const routeNames = {
  home: '/',
  food: '/food',
  tickets: '/tickets',
  partners: '/partners',
  contact: '/contact',
  volunteer: '/volunteer',
  about: '/about',
  program: '/program',
  individualevent: '/individualevent',
  ocreate: '/ocreate',
};

//footer Text data
export const footerMapping = [
  {
    title: 'OFFICE',
    location: 'SLAGTEHUSGADE 15 - 17',
    address: '1715 - COPENHAGEN',
  },
  {
    title: 'FESTIVAL SITE',
    location: 'SØNDER HOVED, REFSHALEØEN',
    address: '1432 - COPENHAGEN',
  },
];

//footer Img data
export const footerLogoMapping = [
  [
    { image: abenLogo, url: 'https://www.aabenbryg.dk/' },
    { image: liveNationalLogo, url: 'https://www.livenation.dk/en' },
    { image: clugerLogo, url: 'https://luger.dk/' },
    { image: royalOakLogo, url: 'https://royalunibrew.dk/brand/royal-beer/' },
  ],
  [
    { image: anarkistLogo, url: 'https://anarkistbrewery.dk/' },
    { image: bombaySapphireLogo, url: 'https://www.bombaysapphire.com/' },
    { image: drjartLogo, url: 'https://www.drjart.com/' },
    { image: clinicquelLogo, url: 'https://www.clinique.dk/' },
  ],
  [
    { image: netCompanyLogo, url: 'https://netcompany.com/' },
    { image: redBullLogo, url: 'https://www.redbull.com/in-en' },
    { image: sticksNSushilLogo, url: 'https://sticksnsushi.com/en' },
  ],
];

//homeBannerImages data:-
export const homeBannerImagesData = [
  homeBannerImgOne,
  homeBannerImgTwo,
  homeBannerImgThree,
  homeBannerImgFour,
  homeBannerImgFive,
  homeBannerImgSix,
  homeBannerImgSeven,
  homeBannerImgEight,
];

//home page images data :-
export const homeImagesData = [
  {
    image: aboutGalleryImgNine,
    title: 'VOLUNTEER',
    descp: `Build O Days with us`,
    navigate: routeNames.volunteer,
  },
  {
    image: aboutGalleryImgEight,
    title: 'ABOUT',
    descp: 'Read more about O Days',
    navigate: routeNames.about,
  },
  {
    image: [dummyImgOne, dummyImgTwo, dummyImgThree, dummmyImgFour],
    title: 'PROGRAM',
    descp: 'See the program for  2024',
    navigate: routeNames.program,
  },
];

export const homeMiddleImagesData = [
  {
    image: aboutGalleryImgEight,
  },
  {
    image: aboutGalleryImgNine,
  },
  {
    image: [dummyImgOne, dummyImgTwo, dummyImgThree, dummmyImgFour],
  },
];

export const homeMiddleDescpData = [
  {
    // image: aboutGalleryImgNine,
    title: 'VOLUNTEER',
    descp: `Build O Days with us`,
    navigate: routeNames.volunteer,
  },
  {
    // image: aboutGalleryImgEight,
    title: 'ABOUT',
    descp: 'Read more about O Days',
    navigate: routeNames.about,
  },
  {
    // image: [dummyImgOne, dummyImgTwo, dummyImgThree, dummmyImgFour],
    title: 'PROGRAM',
    descp: 'See the program for  2024',
    navigate: routeNames.program,
  },
];

//partners card data
export const partnersCardData = [
  {
    cardTitle: 'Royal',
    cardDescp:
      'High quality beers brewed in Denmark from organic ingredients. Available to enjoy all festival days.',
    urlLink: 'https://royalunibrew.dk/brand/royal-beer/',
  },
  {
    cardTitle: 'Anarkist',
    cardDescp:
      'Craft beer driven by curiosity and 160 years of tradition. Available to enjoy all festival days.',
    urlLink: 'https://anarkistbrewery.dk/',
  },
  {
    cardTitle: 'Bombay Sapphire',
    cardDescp:
      'Tantalizing, smooth gin. Here to stir creativity. Available to enjoy all festival days.',
    urlLink: 'https://www.bombaysapphire.com/',
  },
  {
    cardTitle: 'Clinique',
    cardDescp:
      'Dermatologist guided solutions that are all allergy tested, 100% fragrance free. Clinique’s mint green bike will cover your sun protection needs at the festival site.',
    urlLink: 'https://www.clinique.dk/',
  },
  {
    cardTitle: 'Dr.Jart+',
    cardDescp:
      'Skincare’s best ingredients and innovations joined with artful experiences. Visit the Dr.Jart+ booth at O Days to try the products.',
    urlLink: 'https://www.drjart.com/',
  },

  {
    cardTitle: 'Netcompany',
    cardDescp:
      'Changing Europe with responsible digitalisation. Present at O Days with the exhibition A Human Experience celebrating new artistic talent.',
    urlLink: 'https://netcompany.com/',
  },
  {
    cardTitle: 'Red Bull',
    cardDescp:
      'Giving wings to people and ideas since 1987. At O Days Red Bull makes our daily Silent Disco happen.',
    urlLink: 'https://www.redbull.com/in-en',
  },
  {
    cardTitle: `Sticks'n'Sushi`,
    cardDescp:
      'High-quality, fresh food based on a unique combination of traditional sushi and yakitori sticks. Available to enjoy all festival days.',
    urlLink: 'https://sticksnsushi.com/en',
  },
];

export const contactMailIdData = [
  {
    title: 'Contact',
    mailId: 'info@odays.dk',
    mailIdLink: 'mailto:info@odays.dk',
  },
  {
    title: 'Volunteer',
    mailId: 'frivillig@odays.dk',
    mailIdLink: 'mailto:frivillig@odays.dk',
  },
  {
    title: 'Press',
    mailId: 'johan.vardrup@odays.dk',
    mailIdLink: 'mailto:johan.vardrup@odays.dk',
  },
];

export const aboutTapOnLeftImgData = [
  aboutBannerImgOne,
  aboutBannerImgTwo,
  aboutBannerImgThree,
  aboutBannerImgFour,
  aboutBannerImgFive,
];

export const aboutTapOnRightImgData = [
  aboutBannerImgSix,
  aboutBannerImgSeven,
  aboutBannerImgEight,
  aboutBannerImgNine,
  aboutBannerImgTen,
];

export const volunteerImgData = [volunteerImgTwo, volunteerImgThree];

export const ticketCardData = [
  {
    title: 'Get Ticket',
    dotSymbol: '·',
  },
];

//ocreate page data:-
export const oCreateLogoData = [
  [oCreateIconOne, oCreateIconTwo, oCreateIconThree],
  [oCreateIconFour, oCreateIconFive, oCreateIconTwo],
];

import React, { useState } from 'react';
import Header from 'components/header';
import Footer from 'components/footer';
import { Outlet } from 'react-router-dom';
import OdaysUpdateModal from 'components/odaysUpdateModal';
import styles from './styles.module.css';

const Layout = () => {
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);

  return (
    <main className={styles.layoutContainerStyle}>
      <Header />
      <Outlet />
      <Footer />
      {/* {showUpdatesModal ? (
        <OdaysUpdateModal setShowUpdatesModal={setShowUpdatesModal} />
      ) : (
        <div
          className={
            showUpdatesModal
              ? styles.odaysUpdateAnimateStyle
              : styles.ODaysUpdateStyle
          }
          onClick={() => setShowUpdatesModal(!showUpdatesModal)}
        > 
          hello
        </div>   
      )} */}
    </main>
  );
};

export default Layout;
